import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constant } from '../constant';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  token: string;

  constructor(
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    this.token = this.storage.getItem(constant.authData.access_token);
    if (!this.token) {

      return next.handle(request);
    }
    const modifiedReq = request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.token
      }
    });
    return next.handle(modifiedReq);
  }
  get storage(): Storage {
    if (localStorage.getItem('rememberMe')) {
      return localStorage;
    } else {
      return sessionStorage;
    }
  }
}
