import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { CountryModel, RegistrationService } from 'build/openapi';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { fromEvent, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, share, throttleTime } from 'rxjs/operators';
import { AccountService } from '../services/account.service';
import { WarningComponent } from '../shared/warning/warning.component';

enum Direction {
  Up = 'Up',
  Down = 'Down'
}

@Component({
  selector: 'app-main-landing-page',
  templateUrl: './main-landing-page.component.html',
  styleUrls: ['./main-landing-page.component.scss'],

})
export class MainLandingPageComponent implements OnInit, AfterViewInit {
  @Input() landingPage: boolean;
  @Input() specialPage: boolean;
  @Output() toggle = new EventEmitter();
  @ViewChild(CarouselComponent) carousel: CarouselComponent;
  authentication = false;
  isHide = true;
  loginSubcription: Subscription;
  isCollegeLogin: boolean;
  isStudentLogin: boolean;
  isAdminLogin: boolean;
  isNotTransparent = false;
  degreeTypes = [
    { id: 1, name: 'Matriculation' },
    { id: 2, name: 'Senior Secondary' },
    { id: 3, name: 'Bachelors Degree' },
    { id: 4, name: 'Masters Degree' },
  ];
  destinationList=[
    {name:'CANADA'},
    { name:'UNITED KINGDOM'},
    { name:'AUSTRALIA'},
    {name:'UNITED STATAES'}
  ]

  testimonialOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    center: true,
    autoHeight: true,
    autoWidth: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    startPosition: 1,
    navSpeed: 700,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      940: {
        items: 3,
      },
    },
    nav: false,
    autoplayHoverPause: false,
    autoplaySpeed: 1000,
    lazyLoad: true,

  };


  backTransparentPages: string[] = [
    '/home'
  ];
  landingPageForm = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(1), Validators.pattern('^[a-zA-Z]+$')],],
    email: ['', [Validators.required, Validators.email]],
    phoneNo: ['', [Validators.required, Validators.pattern('^[6-9]{1}[0-9]{9}$')],],
    address: ['', Validators.required],
    country: ['', Validators.required],
    educationType: ['', Validators.required],
    completionYear: ['', Validators.required],
    schoolOrCollegeName: ['', Validators.required],
    destination: ['', Validators.required]


  });
  currentRoute: string;
  private isVisible = true;
  displayURL: any;
  selectedEducationType: any;
  degreeTypeName: string;
  isLoading: boolean;
  registerForm: any;
  countryList: CountryModel[];
  // countryCode: string;
  countryCode = '+91';
  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    if (window.scrollY > 100) {
      this.isNotTransparent = true;
    }
    else {
      this.isNotTransparent = false;
    }
  }

  collegeCount: number = 0;
  collegeCountStop: any = setInterval(() => {
    this.collegeCount++;
    if (this.collegeCount == 200) {
      clearInterval(this.collegeCountStop)
    }
  }, 10)
  admissionCount: number = 0;
  admissionCountStop: any = setInterval(() => {
    this.admissionCount++;
    if (this.admissionCount == 280) {
      clearInterval(this.admissionCountStop)
    }
  }, 10)
  applicantsCount: number = 0;
  applicantsCountStop: any = setInterval(() => {
    this.applicantsCount++;
    if (this.applicantsCount == 150) {
      clearInterval(this.applicantsCountStop)
    }
  }, 10)

  constructor(private accountService: AccountService,
    private registrationService: RegistrationService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private sanitizer: DomSanitizer) {
    this.displayURL = sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/Nw2956lWtaw');

  }
  ngOnDestroy(): void {
    this.loginSubcription.unsubscribe();
  }

  ngAfterViewInit() {
    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(10),
      map(() => window.pageYOffset),
      pairwise(),
      map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
      distinctUntilChanged(),
      share()
    );

    const scrollUp$ = scroll$.pipe(
      filter(direction => direction === Direction.Up)
    );

    const scrollDown = scroll$.pipe(
      filter(direction => direction === Direction.Down)
    );

    scrollUp$.subscribe(() => (this.isVisible = true));


  }


  ngOnInit(): void {

    this.landingPageForm.get('educationType').valueChanges.subscribe((val) => {
      if (val) {
        this.selectedEducationType = val;
        if (this.selectedEducationType == 1) {
          this.degreeTypeName = 'Matriculation';
        } else if (this.selectedEducationType == 2) {
          this.degreeTypeName = 'Senior Secondary';
        } else if (this.selectedEducationType == 3) {
          this.degreeTypeName = 'Bachelors Degree';
        } else if (this.selectedEducationType == 4) {
          this.degreeTypeName = 'Masters Degree';
        }
      }
    });
    this.loginSubcription = this.accountService.loginListner$.subscribe(data => {
      this.authentication = data;
      this.isCollegeLogin = this.accountService.isCollegeLogin;
      this.isStudentLogin = this.accountService.isStudentLogin;
      this.isAdminLogin = this.accountService.isAdminLogin;
      if (this.accountService.isAdminLogin) {
        this.isHide = false;
      }
      else {
        this.isHide = true;
      }
    });
    this.registrationService.apiRegistrationCountryDropDownGet().subscribe((res)=>{
      this.countryList = res;
    })
  }

  toFreeCouncelling() {
    document.getElementById('freeCouncelling').scrollIntoView({ behavior: 'smooth' });
  }

  toScrollAbout() {
    document.getElementById('aboutUsLand').scrollIntoView({ behavior: 'smooth' });
  }

  logOut() {
    const dialogRef = this.dialog.open(WarningComponent, {
      width: '400px',
      data: {
        title: 'Log Out',
        content: 'Are you sure? you want to LOGOUT?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.accountService.logout();
        this.isHide = true;
      }
    });
  }
  trigger = 0;
  mouseEnter(trigger: MatMenuTrigger) {
    setTimeout(() => {
      if (!trigger.menuOpen) {
        trigger.openMenu();
      }
      this.trigger = 0;
    }, 1);
  }
  mouseLeave(trigger: MatMenuTrigger) {
    setTimeout(() => {
      if (trigger.menuOpen && this.trigger === 0) {
        trigger.closeMenu();
      }
    }, 200);
  }
  menuMouseLeave(trigger: MatMenuTrigger) {
    setTimeout(() => {
      if (trigger.menuOpen) {
        trigger.closeMenu();
      }
      this.trigger = 0;
    }, 1);
  }
  onSubmit() {
    console.log(this.landingPageForm)
    if (this.landingPageForm.valid) {
      this.isLoading = true;
      let getInTouchModel = {
        name: this.landingPageForm.get('name').value,
        email: this.landingPageForm.get('email').value,
        mobile:this.landingPageForm.get('phoneNo').value,
        address:this.landingPageForm.get('address').value,
        year: this.landingPageForm.get('completionYear').value,
        schoolOrCollege: this.landingPageForm.get('schoolOrCollegeName').value,
        studyDestination: this.landingPageForm.get('destination').value,
      }
      console.log(getInTouchModel);
      this.landingPageForm.reset();
      try {
        this.registrationService.apiRegistrationAddGetInTouchPost(getInTouchModel).subscribe((res => {
          if (res.statusCode === 200) {
        
            this._snackBar.open(res.message, 'Okay', {
              duration: 3000,
            });
          }
          else {
        
            this._snackBar.open(res.message, 'OK', {
              duration: 3000,
            });
          }
        }
        )
        )
      }
      catch {
       
      }
    }
  }
  onCountryChange(args: any) {
    this.countryCode = '+' + args.dialCode;
  }
}

