import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { slideInAnimation } from '../animations/slide-in.animation';
import { AccountService } from '../services/account.service';

@Component({
  selector: 'app-landing-pages',
  templateUrl: './landing-pages.component.html',
  styleUrls: ['./landing-pages.component.scss'],
  animations: [slideInAnimation],
})
export class LandingPagesComponent implements OnInit {

  specialPages: string[] = [
    '/user/search-college',
  ];
  specialPage = true;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private accontService: AccountService
  ) {
    this.router.events.subscribe(data => {
      if (data instanceof NavigationEnd) {
        const url = data.urlAfterRedirects.split('?')[0];
        try {
          if (this.specialPages.findIndex(x => x === url) > -1) {
            this.specialPage = true;
          }
          else {
            this.specialPage = false;
          }
        } catch (error) {
          this.specialPage = true;
        }
      }
      else {
      }
    });
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  ngOnInit(): void {
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
