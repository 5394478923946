import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { AdminService, ChatMessageModel, HomeService } from 'build/openapi';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  isForm = true;
  emailForm: FormGroup;
  email: any;
  chatForm: FormGroup;
  // chatDetailes: ChatMessageModel[];
  timeLeft = 5;
  interval;
  isRefresh = true;
  constructor(
    private fb: FormBuilder,
    private snakbar: MatSnackBar,
    // private homeServices: HomeService,
    // private adminService: AdminService,
    public dialogRef: MatDialogRef<ChatComponent>
  ) { }

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
    });
    this.emailForm.get('email').valueChanges.subscribe(data => {
      this.email = data;
    });
    this.chatForm = this.fb.group({
      email: [''],
      message: ['', Validators.required],
    });
  }

  ClearForm() {
    this.chatForm.reset();
  }

  onSubmit() {
    if (this.emailForm.valid) {
      this.isForm = false;
      this.chats();
      document.getElementById('chatScroll').scrollIntoView({ behavior: 'smooth' });
    }
    else {
      this.snakbar.open('Please Enter Vaild E-mail Id', null, { duration: 2000 });
    }
  }

  onSend() {
    if (this.chatForm.valid) {
      // this.homeServices.apiHomeSendMessagePost(this.email, this.chatForm.get('message').value).subscribe(data => {
      //   this.chats();
      //   this.ClearForm();
      //   this.startTimer();
      // });
      document.getElementById('chatScroll').scrollIntoView({ behavior: 'smooth' });
    }
  }

  chats() {
    // this.adminService.apiAdminGetChatMessagesGet(this.email).subscribe(data => {
    //   this.chatDetailes = data;
    // });
    document.getElementById('chatScroll').scrollIntoView({ behavior: 'smooth' });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.chats();
        this.timeLeft = 5;
      }
    }, 1000);
    document.getElementById('chatScroll').scrollIntoView({ behavior: 'smooth' });
  }

  pauseTimer() {
    clearInterval(this.interval);
    this.dialogRef.close();
  }
}
