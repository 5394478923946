<div style="background-color: #002043;padding: 50px 0 0 0">
  <div class="padding" fxLayout="row wrap" fxLayoutGap="20px" fxLayoutGap.lt-md="20px" fxLayoutGap.lt-sm="0px">
    <div fxFlex="calc(45%-20px)" fxFlex.lt-md="calc(100%-20px)" fxFlex.lt-sm="calc(100%-0px)">
      <div  fxLayout="column" fxLayoutGap="20px" class="mb-20" >
        <div fxLayout="column" fxLayoutGap="10px">
          <img alt="collegenodu Logo" class="link" routerLink="/" src="../../assets/images/homeScreen/EduQuest_logo.png"
            width="230px">
        </div>

        <div class="w80" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column">
              <div fxLayout="row" fxLayoutGap="5px">
                <mat-icon class="aTxt">location_on</mat-icon>
              <p class="font-ss line6 wTxt pbc tc lcc font-vsv line2 mline3 tline3">Head Office: 77 City Center Drive, Suite 501,East Tower, Mississauga, ON, L5B 1M5</p>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="calc(15%-20px)" fxFlex.lt-md="calc(100%-20px)" fxFlex.lt-sm="calc(100%-0px)">
      <div class="mb-20" fxLayout="column" fxLayoutGap="15px">
        <p class="txt20 aTxt pbh">Quick Links</p>
        <div class="footer-list" fxLayout="column">
        <!-- <a class="font-ss line6 wTxt link pbc tc lcc font-vsv line2 mline3" routerLink="/" [routerLinkActive]="['aTxt']">Home</a> -->
        <a class="font-ss line6 wTxt link pbc tc lcc font-vsv line2 mline3 tline3" routerLink="/countries" [routerLinkActive]="['aTxt']">Study Abroad</a>
        <a class="font-ss line6 wTxt link pbc tc lcc font-vsv line2 mline3 tline3" routerLink="/counseling" [routerLinkActive]="['aTxt']">Free Councelling</a>
        <a class="font-ss line6 wTxt link pbc tc lcc font-vsv line2 mline3 tline3" (mouseenter)="mouseEnter(itServicesTrigger)"
        (mouseleave)="mouseLeave(itServicesTrigger)" #itServicesTrigger="matMenuTrigger"
        [matMenuTriggerFor]="itServicesMenu" routerLink="/it-service" [routerLinkActive]="['aTxt']">
        Services
      </a>
      <mat-menu #itServicesMenu="matMenu" xPosition="after" [hasBackdrop]="false" fxLayoutAlign="center center">
        <span (mouseleave)="menuMouseLeave(itServicesTrigger)" (mouseenter)="trigger = 1">
          <button class="btxt txtMedium lcc" mat-menu-item routerLink="/edtech" [routerLinkActive]="['aTxt']">Edtech</button>
            <button class="btxt txtMedium pbc tc lcc font-vsv line2 mline3" mat-menu-item routerLink="/it-service"
            [routerLinkActive]="['aTxt']">IT services</button>
          <button class="btxt txtMedium pbc tc lcc font-vsv line2 mline3" mat-menu-item routerLink="/consulting"
            [routerLinkActive]="['aTxt']">Consulting</button>
            <button class="btxt txtMedium pbc tc lcc font-vsv line2 mline3" mat-menu-item routerLink="/training"
            [routerLinkActive]="['aTxt']">Training</button>
            <button class="btxt txtMedium pbc tc lcc font-vsv line2 mline3" mat-menu-item routerLink="/visa-consultation"
            [routerLinkActive]="['aTxt']">Visa Consultation</button>
        </span>
      </mat-menu>
        <a class="font-ss line6 wTxt link pbc tc lcc font-vsv line2 mline3 tline3" routerLink="/about-us" [routerLinkActive]="['aTxt']">About Us</a>
        <a class="font-ss line6 wTxt link pbc tc lcc font-vsv line2 mline3 tline3" routerLink="/gallery" [routerLinkActive]="['aTxt']">Gallery</a>
        <a class="font-ss line6 wTxt link pbc tc lcc font-vsv line2 mline3 tline3" routerLink="/contact-us" [routerLinkActive]="['aTxt']">Contact Us</a>
        <!-- <a class="font-ss line6 wTxt link pbc tc lcc font-vsv line2 mline3" routerLink="user/bangalore" [routerLinkActive]="['aTxt']">Bangalore</a>
        <a class="font-ss line6 wTxt link pbc tc lcc font-vsv line2 mline3" routerLink="user/hyderabad" [routerLinkActive]="['aTxt']">Hyderabad</a>
        <a class="font-ss line6 wTxt link pbc tc lcc font-vsv line2 mline3" routerLink="user/visakhapatnam" [routerLinkActive]="['aTxt']">Visakpatanam</a> -->
        </div>
      </div>
    </div> 
    <div fxFlex="calc(25%-20px)" fxFlex.lt-md="calc(100%-20px)" fxFlex.lt-sm="calc(100%-0px)">
      <div class="mb-20" fxLayout="column" fxLayoutGap="15px">
        <p class="txt20 aTxt pbh">Reach Us</p>
        <div fxLayout="column" fxLayoutGap="15px">
          <div fxLayout="row" fxLayoutAlign="none center" fxLayoutGap="5px">
            <mat-icon class="aTxt">mail_outline</mat-icon>
            <a href="mailto:info@eduquesthub.com" class="font-ss line6 wTxt pbc tc lcc font-vsv line2" >
              info@eduquesthub.com</a>
          </div>
          <div fxLayout="row" fxLayoutGap="5px">
            <mat-icon class="aTxt">support_agent</mat-icon>
            <a href="tel:+1 (647) 669-7374" class="font-ss line6 wTxt pbc tc lcc font-vsv line2 mline3"
              >+1 (647) 669-7374</a>
          </div>
          <div  fxLayout="row" fxLayoutGap="10px" class="socialIcons">
            <button mat-icon-button matTooltip="Facebook" matTooltipPosition="below">
              <a href="https://www.facebook.com/eduquesthub" target="_blank">
                <img alt="Facebook logo" src="../../assets/images/icons/fb.png">
              </a>
            </button>
            <button mat-icon-button matTooltip="Twitter" matTooltipPosition="below">
              <a href="https://twitter.com/eduquesthub" target="_blank">
                <img alt="Twitter logo" src="../../assets/images/icons/twitter.png">
              </a>
            </button>
            <button mat-icon-button matTooltip="Instagram" matTooltipPosition="below">
              <a href="https://www.instagram.com/eduquesthub/" target="_blank">
                <img alt="Instagram logo" src="../../assets/images/icons/insta.png">
              </a>
            </button>
            <button mat-icon-button matTooltip="LinkedIn" matTooltipPosition="below">
              <a href="https://www.linkedin.com/company/eduquesthub/" target="_blank">
                <img alt="LinkedIn logo" src="../../assets/images/icons/linkedin.png">
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div style="background-color: #071D33;" class="padding">
  <div style="padding: 5px 0;" fxLayout.lt-sm="column" fxLayoutAlign="center center"
    fxLayoutAlign.lt-sm="center">
    <!-- <div fxFlex="50%" fxFlex.lt-sm="100%" fxLayoutAlign.lt-sm="center center" fxLayout="row wrap">
      <a mat-button class="wTxt" routerLink="/user/about-us">About Us</a>
      <a mat-button class="wTxt" routerLink="/user/contact-us">Contact Us</a>
      <a mat-button class="wTxt" routerLink="/user/articles">Articles</a>
    </div> -->
    <p fxLayout="row" fxLayoutAlign="center center" fxFlex="50%" fxFlex.lt-sm="100%" class="wTxt font-xvss copyrightTxt LessMargin pbc txtCenter">@ 2023 Copyright & All Rights are Reserved by EduQuest Hub Company Ltd.</p>
  </div>
</div>