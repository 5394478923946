import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLandingPageComponent } from './main-landing-page/main-landing-page.component';
import{ CanadaUniversityListModule} from './canada-university-list/canada-university-list.module'

const routes: Routes = [
  // {
  //   path: '', redirectTo: 'user', pathMatch: 'full'
  // },
  {
    path: '', loadChildren: () => import('../app/landing-pages/landing-pages.module').then(m => m.LandingPagesModule)
  },
  {
    path: '', loadChildren: () => import('../app/canada-university-list/canada-university-list.module').then(m => m.CanadaUniversityListModule)
  },

  {
    path: 'info', component: MainLandingPageComponent
  },

 

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
