import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-upload-cover-image',
  templateUrl: './upload-cover-image.component.html',
  styleUrls: ['./upload-cover-image.component.scss']
})
export class UploadCoverImageComponent implements OnInit {
  fileName: string;
  uploadImageForm: FormGroup;
  blob: Blob;
  file: File;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<UploadCoverImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.uploadImageForm = this.fb.group({
      photo: ['', Validators.required],
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.fileName = event.target.files[0].name;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const arr = event.base64.split(',');
    this.uploadImageForm.get('photo').patchValue(arr[1]);
  }

  onSubmit() {
    if (this.uploadImageForm.valid) {
      this.dialogRef.close({
        result: true,
        fileName: this.fileName,
        photoPath: this.uploadImageForm.get('photo').value,
        Image: this.croppedImage
      });
    }
    else {
      this.snackbar.open('Please select an image', null, { duration: 2000 });
    }
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}
