import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { fromEvent, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, share, throttleTime } from 'rxjs/operators';
import { AccountService } from '../services/account.service';
import { WarningComponent } from '../shared/warning/warning.component';

enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

enum Direction {
  Up = 'Up',
  Down = 'Down'
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('toggle1', [
      state(
        VisibilityState.Hidden,
        style({ opacity: 0, transform: 'translateY(-100%)' })
      ),
      state(
        VisibilityState.Visible,
        style({ opacity: 1, transform: 'translateY(0)' })
      ),
      transition('* => *', animate('200ms ease-in'))
    ])
  ]
})


export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() landingPage: boolean;
  @Input() specialPage: boolean;
  @Output() toggle = new EventEmitter();
  authentication = false;
  isHide = true;
  loginSubcription: Subscription;
  isCollegeLogin: boolean;
  isStudentLogin: boolean;
  isAdminLogin: boolean;
  isNotTransparent = false;

  backTransparentPages: string[] = [
    '/user/home'
  ];

  currentRoute: string;
  // forHeader
  private isVisible = true;
  @HostBinding('@toggle1')
  get toggle1(): VisibilityState {
    return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    if (window.scrollY > 100) {
      this.isNotTransparent = true;
    }
    else {
      this.isNotTransparent = false;
    }
  }

  constructor(
    private accountService: AccountService,
    private dialog: MatDialog,
    private location: Location
  ) { }

  ngOnDestroy(): void {
    this.loginSubcription.unsubscribe();
  }

  ngAfterViewInit() {
    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(10),
      map(() => window.pageYOffset),
      pairwise(),
      map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
      distinctUntilChanged(),
      share()
    );

    const scrollUp$ = scroll$.pipe(
      filter(direction => direction === Direction.Up)
    );

    const scrollDown = scroll$.pipe(
      filter(direction => direction === Direction.Down)
    );

    scrollUp$.subscribe(() => (this.isVisible = true));
    scrollDown.subscribe(() => (this.isVisible = false));
  }


  ngOnInit(): void {
    this.loginSubcription = this.accountService.loginListner$.subscribe(data => {
      this.authentication = data;
      this.isCollegeLogin = this.accountService.isCollegeLogin;
      this.isStudentLogin = this.accountService.isStudentLogin;
      this.isAdminLogin = this.accountService.isAdminLogin;
      if (this.accountService.isAdminLogin) {
        this.isHide = false;
      }
      else {
        this.isHide = true;
      }
    });
  }

  toFreeCouncelling() {
    document.getElementById('freeCouncelling').scrollIntoView({ behavior: 'smooth' });
  }

  toScrollAbout() {
    document.getElementById('aboutUsLand').scrollIntoView({ behavior: 'smooth' });
  }

  logOut() {
    const dialogRef = this.dialog.open(WarningComponent, {
      width: '400px',
      data: {
        title: 'Log Out',
        content: 'Are you sure? you want to LOGOUT?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.accountService.logout();
        this.isHide = true;
      }
    });
  }
  trigger = 0;
  goToBack() {
    this.location.back();
  }
  mouseEnter(trigger: MatMenuTrigger) {
    setTimeout(() => {    
      if (!trigger.menuOpen) {
        trigger.openMenu();
      }
      this.trigger = 0;
    }, 1);
  }
  mouseLeave(trigger: MatMenuTrigger) {
    setTimeout(() => {
      if (trigger.menuOpen && this.trigger === 0) {
        trigger.closeMenu();
      }
    }, 200);
  }
  menuMouseLeave(trigger: MatMenuTrigger) {
    setTimeout(() => {
      if (trigger.menuOpen) {
        trigger.closeMenu();
      }
      this.trigger = 0;
    }, 1);
  }
}
