import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { slideInAnimation } from './animations/slide-in.animation';
import { AccountService } from './services/account.service';
import { ChatComponent } from './shared/chat/chat.component';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit {
  scrolled = false;
  switchingModule = false;
  windowScrolled = false;

  title = 'COLLEGENODU';
  @ViewChild('drawer') sideNav: MatSidenav;
  authentication = false;
  SideNavPage = false;
  headerFooterPage = false;
  BannerSideNavHeaderFooter = false;
  loginSubcription: Subscription;
  accountPage = false;
  landingPage = false;
  BannerPage = false;
  specialPage = false;
  accountPages: string[] = [
    '/account',
    '/account/sign-up',
    '/account/sign-in',
    '/account/forgot-password',
    '/account/reset'
  ];
  BannerPages: string[] = [
    '/',
    '/college',
  ];
  landingPages: string[] = [
    '/contact-us',
    '/it-service',
    '/edtech',
    '/training',
    '/consulting',
    '/registration',
    '/new-registaration',
    '/about-us',
    '/caf-college',
    '/caf-course',
    '/search-college',
    '/search-college-new',
    '/articles',
    '/all-articles',
    '/countries',
    '/states',
    '/cities',
    '/college-on-country',
    '/counseling',
    '/exams',
    '/gallery',
    '/visa-consultation',
    '/canada-colleges',
    '/uk-colleges',
    '/us-colleges',
    '/australia-colleges',
    '/waterloo-university'
  ];


  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  isCollegeLogin: boolean;
  isStudentLogin: boolean;
  isAdminLogin: boolean;

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    if (window.scrollY > 100) {
      this.scrolled = window.scrollY > 100;
      this.windowScrolled = true;
    } else {
      this.scrolled = false;
      this.windowScrolled = false;
    }
  }

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private accontService: AccountService,
    private dialog: MatDialog
  ) {
    this.router.events.subscribe(data => {

      if (data instanceof NavigationEnd) {
        const url = data.urlAfterRedirects.split('?')[0];
        try {
          if (this.BannerPages.findIndex(x => x === url) > -1) {
            this.BannerPage = true;
            this.landingPage = false;
            this.accountPage = false;
            this.specialPage = false;
          }
          else if (this.landingPages.findIndex(x => x === url) > -1) {
            this.BannerPage = false;
            this.landingPage = true;
            this.accountPage = false;
            this.specialPage = false;
          }
          else if (this.accountPages.findIndex(x => x === url) > -1) {
            this.BannerPage = false;
            this.landingPage = false;
            this.accountPage = true;
            this.specialPage = false;
          }
          else if (
            url.substr(1, 8) === 'student/' ||
            url.substr(1, 8) === 'college/' ||
            url.substr(1, 6) === 'admin/'
          ) {
            this.specialPage = true;
            this.BannerPage = false;
            this.landingPage = false;
            this.accountPage = false;
          }
          else {
            this.BannerPage = false;
            this.landingPage = false;
            this.accountPage = false;
            this.specialPage = false;
          }
        } catch (error) {
          this.BannerPage = true;
          this.landingPage = true;
          this.accountPage = true;
          this.specialPage = true;
        }
      }
      else {

      }
    });

    this.accontService.checkLogin();
  }

  ngOnInit(): void {
    this.loginSubcription = this.accontService.loginListner$.subscribe(data => {
      this.authentication = data;
      this.isCollegeLogin = this.accontService.isCollegeLogin;
      this.isStudentLogin = this.accontService.isStudentLogin;
      this.isAdminLogin = this.accontService.isAdminLogin;
    });
    AOS.init();
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 5));
      }
    })();
  }

  onChat() {
    const dialogRef = this.dialog.open(ChatComponent, {
      disableClose: true,
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
export interface ModuleRouteModel {
  studentRoute: boolean;
  collegeRoute: boolean;
}

