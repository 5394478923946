<mat-toolbar class="padding transparentBack" [ngClass]="{'whiteBg mat-elevation-z4': isNotTransparent}"
  fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="authentication && !landingPage && !specialPage">
      <button mat-icon-button class="color-white" (click)="toggle.emit()" fxHide fxShow.lt-md>
        <mat-icon class="wTxt">menu</mat-icon>
      </button>
    </div>
    <button mat-icon-button *ngIf="isAdminLogin" (click)="goToBack()">
      <mat-icon class="wTxt">arrow_back</mat-icon>
    </button>
    <div>
      <a routerLink="/" fxLayout="column" fxLayoutAlign="start center">
        <img alt="CollegeNodu Logo" src="../../assets/images/homeScreen/EduQuest_logo.png" width="200px">
      </a>
    </div>
  </div>
  <div fxShow fxHide.lt-md fxLayout="row" fxLayoutGap="15px">
    <!-- <a *ngIf="isHide" class="wTxt txtMedium font-vv" mat-button routerLink="/" [routerLinkActive]="['aTxt']">
      HOME
    </a> -->
  
    <a *ngIf="isHide" class="wTxt txtMedium font-vv" mat-button routerLink="/about-us" [routerLinkActive]="['aTxt']">ABOUT
      US</a>
    <a *ngIf="isHide" class="wTxt txtMedium font-vv" mat-button (mouseenter)="mouseEnter(itServicesTrigger)"
      (mouseleave)="mouseLeave(itServicesTrigger)" #itServicesTrigger="matMenuTrigger"
      [matMenuTriggerFor]="itServicesMenu" >
      SERVICES<mat-icon>keyboard_arrow_down</mat-icon>
    </a>
    <mat-menu #itServicesMenu="matMenu" [hasBackdrop]="false" fxLayoutAlign="center center">
      <span (mouseleave)="menuMouseLeave(itServicesTrigger)" (mouseenter)="trigger = 1">
        <button class="btxt txtMedium font-vv pbc" mat-menu-item routerLink="/edtech" [routerLinkActive]="['aTxt']">ED
          TECH</button>
          <button class="btxt txtMedium font-vv pbc" mat-menu-item routerLink="/it-service"
          [routerLinkActive]="['aTxt']">IT SERVICES</button>
        <button class="btxt txtMedium font-vv pbc" mat-menu-item routerLink="/consulting"
          [routerLinkActive]="['aTxt']">CONSULTING</button>
          <button class="btxt txtMedium font-vv pbc" mat-menu-item routerLink="/training"
          [routerLinkActive]="['aTxt']">TRAINING</button>
          <button class="btxt txtMedium font-vv pbc" mat-menu-item routerLink="/countries"
          [routerLinkActive]="['aTxt']">STUDY ABROAD</button>
          <a class="btxt txtMedium font-vv pbc" mat-menu-item href="http://10x.promena.in/" target="_blank"
          [routerLinkActive]="['aTxt']">IMMIGRATION SERVICES</a>
      </span>
    </mat-menu>


  
      <a *ngIf="isHide" class="wTxt txtMedium font-vv" mat-button routerLink="/gallery" [routerLinkActive]="['aTxt']">GALLERY</a>
    <a *ngIf="isHide" class="wTxt txtMedium font-vv" mat-button routerLink="/contact-us"
      [routerLinkActive]="['aTxt']">CONTACT US</a>
      <!-- <a *ngIf="isHide" class="wTxt txtMedium font-vv" mat-button routerLink="/user/registration"
      [routerLinkActive]="['aTxt']">REGISTER</a> -->
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
    <div fxHide fxShow.lt-md>
      <button *ngIf="isHide" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon class="wTxt largeMatIcon">dashboard</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <!-- <a class=" pbc txtMedium" mat-menu-item routerLink="/" [routerLinkActive]="['aTxt']">HOME</a> -->
        <button mat-menu-item class="pbc txtMedium " routerLink="/countries" [routerLinkActive]="['aTxt']">STUDY
          ABROAD</button>
        <button mat-menu-item class="pbc txtMedium" (click)="toFreeCouncelling()" routerLink="/counseling">FREE
          COUNSELLING</button>
        <button mat-menu-item class="pbc txtMedium" (mouseenter)="mouseEnter(itServicesTrigger)"
          (mouseleave)="mouseLeave(itServicesTrigger)" #itServicesTrigger="matMenuTrigger"
          [matMenuTriggerFor]="itServicesMenu" >
          SERVICE</button>
        <button mat-menu-item class="pbc txtMedium" routerLink="/about-us" routerLinkActive="active">ABOUT
          US</button>
          <button mat-menu-item class="pbc txtMedium" routerLink="/gallery" routerLinkActive="active">GALLERY</button>
        <button mat-menu-item class="pbc txtMedium" routerLink="/contact-us" routerLinkActive="active">CONTACT
          US</button>
          <!-- <button mat-menu-item class="pbc txtMedium" routerLink="/user/registration" routerLinkActive="active">REGISTRATION</button> -->
      </mat-menu>
    </div>
    <!-- <div *ngIf="isCollegeLogin || isStudentLogin" style="padding: 10px 0;">
      <img alt="profile-img" fxFlex="100%" src="../../assets/images/person.png" [matMenuTriggerFor]="belowMenu"
        class="profileOnheader">
      <mat-menu #belowMenu="matMenu" yPosition="below">
        <button *ngIf="isCollegeLogin" mat-menu-item routerLink="college/edit-profile">
          <mat-icon>face</mat-icon>
          <span>Profile</span>
        </button>
        <button *ngIf="isStudentLogin" mat-menu-item routerLink="student/edit-profile">
          <mat-icon>face</mat-icon>
          <span>Profile</span>
        </button>
        <button *ngIf="isAdminLogin" mat-menu-item routerLink="student/edit-profile">
          <button mat-raised-button>
            <mat-icon>logout</mat-icon> Log Out
          </button>
        </button>
        <button mat-menu-item *ngIf="authentication" (click)="logOut()">
          <mat-icon>logout</mat-icon>
          <span>Log Out</span>
        </button>
      </mat-menu>
    </div>
    <div *ngIf="isAdminLogin" (click)="logOut()" style="padding: 10px 0;">
      <button mat-button class="pTxt" class="wTxt transparentBack">
        <mat-icon>logout</mat-icon> Log Out
      </button>
    </div> -->
  </div>
</mat-toolbar>