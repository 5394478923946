import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-canada-university-list',
  templateUrl: './canada-university-list.component.html',
  styleUrls: ['./canada-university-list.component.scss']
})
export class CanadaUniversityListComponent implements OnInit {
onActivate($event: any) {
throw new Error('Method not implemented.');
}
prepareRoute(_t2: RouterOutlet): any {
throw new Error('Method not implemented.');
}

  constructor() { }

  ngOnInit(): void {
  }

}
