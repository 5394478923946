<app-header (toggle)="sideNav.toggle()" *ngIf="!accountPage && specialPage || BannerPage || landingPage" style="z-index:1000">
</app-header>
<mat-sidenav-container class="main" style="overflow: hidden;">
  <mat-sidenav class="min-100vh" *ngIf="!accountPage && !landingPage && !BannerPage && authentication && specialPage" #drawer
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false"
    style="background-color: #474747;">
    <div fxShow fxHide.lt-md>
      <app-side-nav class="outlet"></app-side-nav>
    </div>
    <div fxHide fxShow.lt-md>
      <app-side-nav (click)="drawer.toggle()"></app-side-nav>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="outlet" [@routeAnimations]="prepareRoute(outlet)"
      style="z-index:0">
      <router-outlet #outlet="outlet"></router-outlet>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0px" class="scroll-to-top"
        style="z-index: 2000;;" [ngClass]="{'show-scrollTop': windowScrolled}" *ngIf="windowScrolled && BannerPage || landingPage || specialPage && !isAdminLogin">
        <div class="whatsapp">
          <a href="https://api.whatsapp.com/send?phone=16476697374" target="_blank" class="text-d-n text-primary link-ui"
          aria-label="Admission whatsapp to link">
         <img src="../../../assets/images/whatsapp.png" alt=""></a>
        </div>
     
      </div>
    </div>
   
  </mat-sidenav-content>
</mat-sidenav-container>
<app-footer *ngIf="!accountPage && BannerPage || landingPage || specialPage && !isAdminLogin"></app-footer>