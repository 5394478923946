import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { WarningComponent } from './warning/warning.component';
import { PopUpErrorComponent } from './pop-up-error/pop-up-error.component';
import { PopUpSuccessComponent } from './pop-up-success/pop-up-success.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ShowFullImageComponent } from './show-full-image/show-full-image.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatTreeModule } from '@angular/material/tree';
import { NguCarouselModule } from '@ngu/carousel';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ChartsModule } from 'ng2-charts';
import { UploadCoverImageComponent } from './upload-cover-image/upload-cover-image.component';
import { ChatComponent } from './chat/chat.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SwiperModule } from 'swiper/angular';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { MatStepperModule } from '@angular/material/stepper';

@NgModule({
  imports: [
    ImageCropperModule,
    CarouselModule,
    ChartsModule,
    FlexLayoutModule,
    NguCarouselModule,
    MatTreeModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatTooltipModule,
    MatMenuModule,
    MatDialogModule,
    MatCheckboxModule,
    MatGridListModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatExpansionModule,
    MatListModule,
    MatBottomSheetModule,
    MatChipsModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatSidenavModule,
    AngularEditorModule,
    NgxChartsModule,
    MatRippleModule,
    NgScrollbarModule,
    SwiperModule,
    Ng2TelInputModule,
    MatStepperModule
  ],
  exports: [
    CarouselModule,
    ChartsModule,
    FlexLayoutModule,
    NguCarouselModule,
    MatTreeModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatTooltipModule,
    MatMenuModule,
    MatDialogModule,
    MatCheckboxModule,
    MatGridListModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatExpansionModule,
    MatListModule,
    MatBottomSheetModule,
    MatChipsModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatSidenavModule,
    AngularEditorModule,
    NgxChartsModule,
    MatRippleModule,
    NgScrollbarModule,
    SwiperModule,
    Ng2TelInputModule,
    MatStepperModule

  ],
  declarations: [
    WarningComponent,
    PopUpErrorComponent,
    PopUpSuccessComponent,
    UploadImageComponent,
    ShowFullImageComponent,
    UploadCoverImageComponent,
    ChatComponent  ]
})
export class SharedModule { }
