import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-full-image',
  templateUrl: './show-full-image.component.html',
  styleUrls: ['./show-full-image.component.scss']
})
export class ShowFullImageComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ShowFullImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

}
