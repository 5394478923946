<div class="header">
  <mat-toolbar class="padding " [ngClass]="{'whiteBg mat-elevation-z4': isNotTransparent}"
    fxLayoutAlign="space-around center">
    <div class="w90">
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="60%">
        <a routerLink="/" fxLayout="column" fxLayoutAlign="start none">
          <img src="../../assets/images/homeScreen/EduQuest_logo.png" width="200px">
        </a>
      </div>
      <div fxShow fxHide.lt-md fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="center end" fxFlex="40%">
        <a *ngIf="isHide" class="bTxt txtMedium font-vv" mat-button href="#">
          HOME
        </a>
        <a *ngIf="isHide" class="bTxt txtMedium font-vv" mat-button href="#">
          ABOUT US
        </a>
        <a *ngIf="isHide" class="bTxt txtMedium font-vv" mat-button href="#">
          STUDY ABROAD
        </a>
        <a *ngIf="isHide" class="bTxt txtMedium font-vv">CONTACT
          US</a>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
      <div fxHide fxShow.lt-md>
        <button *ngIf="isHide" mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon class="greyTxt largeMatIcon">dashboard</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <a class=" pbc txtMedium" mat-menu-item routerLink="#" [routerLinkActive]="['aTxt']">HOME</a>
          <button mat-menu-item class="pbc txtMedium " routerLink="#" [routerLinkActive]="['aTxt']">STUDY
            ABROAD</button>
          <button mat-menu-item class="pbc txtMedium" routerLink="#" routerLinkActive="active">CONTACT
            US</button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar>
</div>

<div class="home" fxLayout="column"> 
  <a id="home">
    <div class="home-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">

      <div class="w90 lw70 padtb-50 mb-70 mmar0 " fxLayout="row wrap" fxLayoutGap="30px" fxLayoutGap.lt-md="20px">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="calc(50%-30px)" fxFlex.lt-md="calc(100%-20px)"
          fxFlex.lt-sm="calc(100%-0px)" fxLayoutGap="20px">
          <div class="txtCenter" fxLayout="column" fxLayoutGap="25px">
            <span class="fs-40 txtBold linee4 wTxt tline linevss lfont-l">Your Gateway for Overseas Higher Education and Immigration Assistance</span>
            <span class="fs-22 txtBold line3 wTxt pbh mline6 tline3 lfont-s line2">Looking for higher education in Canada, US, UK, Australia or other destinations</span>
          </div>
          <!-- <button mat-raised-button>Know More</button> -->
        </div>
        <div class="home-content-form mmar-15" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px"
          fxFlex="calc(50%-30px)" fxFlex.lt-md="calc(100%-20px)" fxFlex.lt-sm="calc(100%-0px)">
          <span class="fs-22 txtBold line3 wTxt mar-1tb lfont-s">GET IN TOUCH WITH US</span>
          <form [formGroup]="landingPageForm" class="w90 mw70">
            <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.lt-md="10px" fxLayoutGap.lt-sm="0px">
              <mat-form-field appearance="outline" class="blackBorder" fxFlex="calc(50%-10px)"
                fxFlex.lt-md="calc(100%-10px)" fxFlex.lt-sm="calc(100%-0px)">
                <mat-label class="wTxt font-vv pbc tbc">Name</mat-label>
                <input class="wTxt" matInput placeholder="Enter your name" formControlName="name" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="blackBorder" fxFlex="calc(50%-10px)"
                fxFlex.lt-md="calc(100%-10px)" fxFlex.lt-sm="calc(100%-0px)">
                <mat-label class="wTxt font-vv pbc tbc">Email</mat-label>
                <input class="wTxt" matInput placeholder="Enter your email" formControlName="email" required>
              </mat-form-field>
                <div fxLayout="row nowrap" fxFlex="calc(50%-10px)"
                fxFlex.lt-md="calc(100%-10px)" fxFlex.lt-sm="calc(100%-0px)">
                    <div fxFlex="60px" fxLayout="row" class="country-code-form">
                        <mat-form-field fxFlex="100%" appearance="outline" class="blackBorder">
                            <input matInput ng2TelInput [ng2TelInputOptions]="{initialCountry: 'in'}"
                                (countryChange)="onCountryChange($event)" placeholder="-" formControlName="country">
                        </mat-form-field>
                    </div>
                    <div fxFlex="" fxLayout="row" class="mobile-form">
                        <mat-form-field appearance="outline" class="blackBorder" fxFlex="90%">
                            <mat-label class="wTxt font-vv pbc tbc" >Mobile</mat-label>
                            <input class="wTxt" matInput formControlName="phoneNo" placeholder="Enter mobile number"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                maxlength="10" minlength="10" aria-label="mobile" required>
                        </mat-form-field>
                    </div>
                </div>
              <mat-form-field appearance="outline" class="blackBorder" fxFlex="calc(50%-10px)"
                fxFlex.lt-md="calc(100%-10px)" fxFlex.lt-sm="calc(100%-0px)">
                <mat-label class="wTxt font-vv pbc tbc">City</mat-label>
                <input class="wTxt" matInput placeholder="Enter city " formControlName="address" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="blackBorder" fxFlex="calc(100%-10px)">
                <mat-label  class="wTxt font-vv pbc tbc">Select an Country</mat-label>
                <mat-select formControlName="country" required>
                  <mat-option [value]="item.countryId" *ngFor="let item of countryList">{{
                    item.countryName
                    }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="blackBorder" fxFlex="calc(100%-10px)">
                <mat-label class="wTxt font-vv pbc tbc">Highest Level of Education</mat-label>
                <mat-select name="degreetype" formControlName="educationType" required>
                  <mat-option [value]="data.id" *ngFor="let data of degreeTypes">{{
                    data.name
                    }}</mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="
            selectedEducationType == 1 ||
            selectedEducationType == 2 ||
            selectedEducationType == 3 ||
            selectedEducationType == 4
          " fxLayout="column" fxFlex="calc(100%-20px)" fxFlex.lt-md="calc(100%-20px)" fxFlex.lt-sm="calc(100%-10px)"
                fxLayoutAlign="start none" fxLayoutGap="10px">
                <mat-form-field appearance="outline" class="blackBorder">
                  <mat-label class="wTxt font-vv pbc tbc">Completion Year</mat-label>
                  <input class="wTxt" matInput placeholder="" formControlName="completionYear" required  oninput="this.value= this.value.replace(/[^0-9.]/g,
                  '').replace(/(\..*)\./g, '$1');" autocomplete="off" />
                </mat-form-field>
              </div>
              <div *ngIf="
        selectedEducationType == 1 ||
        selectedEducationType == 2 ||
        selectedEducationType == 3 ||
        selectedEducationType == 4
      " fxLayout="column" fxFlex="calc(100%-20px)" fxFlex.lt-md="calc(100%-20px)" fxFlex.lt-sm="calc(100%-10px)"
                fxLayoutAlign="start none" fxLayoutGap="10px">
                <mat-form-field appearance="outline" class="blackBorder">
                  <mat-label class="wTxt font-vv pbc tbc">School or College</mat-label>
                  <input class="wTxt" matInput placeholder="" formControlName="schoolOrCollegeName" required />
                </mat-form-field>
              </div>
              <div fxLayout="column" fxLayoutGap="20px">
                <mat-label class="wTxt font-vs pbc tbc">Preferred Study Destination</mat-label>
                <div fxLayout="row wrap" fxLayoutGap="20px">
                  <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" fxLayout="row" fxLayout.lt-sm="column"
                  fxLayoutGap="20px" formControlName="destination" required>
                  <mat-radio-button class="wTxt font-vv pbc tbc" [value]="data.name"
                    *ngFor="let data of destinationList">{{data.name}}</mat-radio-button>
                </mat-radio-group>
                </div>
              </div>
            </div>
          </form>

          <button mat-raised-button class="gradient w30" (click)="onSubmit()">Submit</button>
        </div>
      </div>

      <div class="padding list" data-aos="fade-up" data-aos-duration="500" data-aos-offset="150">
        <div fxLayout="row wrap" fxLayoutGap="50px" fxLayoutGap.lt-md="20px" fxLayoutGap.lt-sm="0px">
          <div fxFlex="calc(20%-50px)" fxFlex.lt-md="calc(33% - 20px)" fxFlex.lt-sm="calc(100%-0px)" class="borderBox"
            fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
            <img src="../../assets/images/Landing-page/collage.png" width="40px">
            <p class="wTxt txtRegular txtCenter no-select font-vs pbc tbc line2">FIND THE BEST COLLEGE</p>
          </div>
          <div fxFlex="calc(20%-50px)" fxFlex.lt-md="calc(33%-20px)" fxFlex.lt-sm="calc(100%-0px)" class="borderBox"
            fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
            <img src="../../assets/images/Landing-page/counselling.png" width="40px">
            <p class="wTxt txtRegular txtCenter no-select font-vs pbc tbc line2">FREE COUNSELLING BY EXPERTS</p>
          </div>
          <div fxFlex="calc(20%-50px)" fxFlex.lt-md="calc(33%-20px)" fxFlex.lt-sm="calc(100%-0px)" class="borderBox"
            fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
            <img src="../../assets/images/Landing-page/articles.png" width="40px">
            <p class="wTxt txtRegular txtCenter no-select font-vs pbc tbc line2">READ TRENDING ARTICLES</p>
          </div>
          <div fxFlex="calc(20%-50px)" fxFlex.lt-md="calc(33%-20px)" fxFlex.lt-sm="calc(100%-0px)" class="borderBox"
            fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
            <img src="../../assets/images/Landing-page/study in abroad.png" width="40px">
            <p class="wTxt txtRegular txtCenter no-select font-vs pbc tbc line2">STUDY IN COLLEGES ABROAD</p>
          </div>
          <div fxFlex="calc(20%-50px)" fxFlex.lt-md="calc(33%-20px)" fxFlex.lt-sm="calc(100%-0px)" class="borderBox"
            fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
            <img src="../../assets/images/Landing-page/application.png" width="40px">
            <p class="wTxt txtRegular txtCenter no-select font-vs pbc tbc line2">COMMON APPLICATION FORM</p>
          </div>
          <div class="no-disply" fxFlex="calc(20%-50px)" fxFlex.lt-md="calc(33%-20px)" fxFlex.lt-sm="calc(100%-0px)">
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </a>
  <div class="padding councelling">
    <a id="aboutus">
      <section class="padt-50 mpad0 mt-70 mmar0" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="50px">
        <div class="section-img mt-50 mmar0" fxFlex="calc(50%-50px)" fxFlex.lt-md="calc(50%-0px)"
          fxLayoutAlign="end center" fxLayoutAlign.lt-md="center center">
          <div class="tw65" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <img alt="counselling Image" class="councellingImg"
              src="../../assets/images/Landing-page/talk to experts.png">
            <p class="wTxt font-xxvs txtCenter no-select pbh tbh">1 ON 1 COUNSELLING</p>
          </div>
        </div>
        <div class="mmar0" fxLayout="column" fxLayoutAlign="center center" fxFlex="calc(50%-50px)"
          fxFlex.lt-md="calc(50%-0px)" fxFlex.lt-sm="calc(100%-0px)" fxLayoutGap="20px">
          <p class="font-s bTxt txtBold no-select pbh1 tbh1 padtb-1">TALK TO OUR EXPERTS</p>
          <p class="fs-16 bTxt no-select pnc tnc jtxt mpad2-t mline2 line2 tline3 lfont-m">
            Planning to pursue higher education overseas? We can help you find the best study abroad destinations, most
            suitable courses and prestigious universities in US, Australia, and UK and assist you with the admission
            procedures. Universities in Canada attract a large number of students internationally for their career
            opportunities and academic repute. We provide thorough expert consultation starting from selection of course
            and universities, application services, preparation for interviews, pre-departure and post-landing guidance
            in
            Canada to offer you a seamless journey to achieve your career goals.
          </p>
        </div>

      </section>
    </a>
    <a id="studyabroad">
      <div class="destination padtb-50" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <span class="font-s txtBold padtb-1 pbh1 tbh  pb-50">DESTINATIONS</span>
        <div class="destination-list padb-50 mpad0" fxLayout="row wrap" fxLayoutGap="20px" fxLayoutGap.lt-md="10px"
          fxLayoutGap.lt-sm="0px">
          <div class="destination-list-image mar-10 mar-2tb " fxFlex="calc(25%-20px)" fxFlex.lt-md="calc(50%-10px)"
            fxFlex.lt-sm="calc(100%-0px)">
            <img class="w100 ht-img" src="../../../assets/images/study-abroad-icons/canada.jpg" alt="">
            <div class="destination-list-contents pad-10" fxLayout="column">
              <p class="font-vs wTxt  pbc mline3 mar0">CANADA</p>
            </div>
          </div>
          <div class="destination-list-image mar-10 mar-2tb " fxFlex="calc(25%-20px)" fxFlex.lt-md="calc(50%-10px)"
            fxFlex.lt-sm="calc(100%-0px)">
            <img class="w100 ht-img" src="../../../assets/images/study-abroad-icons/us.jpg" alt="">
            <div class="destination-list-contents pad-10" fxLayout="column">
              <p class="font-vs wTxt  pbc mline3 mar0">UNITED STATAES</p>
            </div>
          </div>
          <div class="destination-list-image mar-10 mar-2tb " fxFlex="calc(25%-20px)" fxFlex.lt-md="calc(50%-10px)"
            fxFlex.lt-sm="calc(100%-0px)">
            <img class="w100 ht-img" src="../../../assets/images/study-abroad-icons/uk.jpg" alt="">
            <div class="destination-list-contents pad-10" fxLayout="column">
              <p class="font-vs wTxt  pbc mline3 mar0">UNITED KINGDOM</p>
            </div>
          </div>
          <div class="destination-list-image mar-10 mar-2tb " fxFlex="calc(25%-20px)" fxFlex.lt-md="calc(50%-10px)"
            fxFlex.lt-sm="calc(100%-0px)">
            <img class="w100 ht-img" src="../../../assets/images/study-abroad-icons/australia.jpg" alt="">
            <div class="destination-list-contents pad-10" fxLayout="column">
              <p class="font-vs wTxt  pbc mline3 mar0">AUSTRALIA</p>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </a>
  </div>
  <div class="services" fxLayout="column" fxLayoutAlign="center center">
    <span class="font-s txtBold padtb-1 pbh1 tbh padtb-50 wTxt">SERVICES</span>
    <div class="w90" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="50px" fxLayoutGap.lt-md="20px"
      fxLayoutGap.lt-sm="0px">
      <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.lt-md="20px"
        fxLayoutGap.lt-sm="10px">
        <div fxFlex="calc(33%-50px)" fxFlex.lt-md="calc(50% - 20px)" fxFlex.lt-sm="calc(100%-10px)" class="borderBox-1"
          fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
          <img src="../../assets/images/Landing-page/Expert counseling.png" width="50px">
          <p class="bTxt txtRegular txtCenter no-select font-sv pbh tbc line2">Expert counseling</p>
          <span class="bTxt txtRegular txtCenter no-select font-xxvs pnc tbc line2 mline2">Each student is assigned a
            highly
            trained personal counselor to help you find the ideal course and institution based on your experience,
            academic ability, and profile.
          </span>
        </div>
        <div fxFlex="calc(33%-50px)" fxFlex.lt-md="calc(50% - 20px)" fxFlex.lt-sm="calc(100%-10px)" class="borderBox-1"
          fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
          <img src="../../assets/images/Landing-page/Course and University Selection.png" width="50px">
          <p class="bTxt txtRegular txtCenter no-select font-sv pbh tbc line2">Course and University</p>
          <span class="bTxt txtRegular txtCenter no-select font-xxvs pnc tbc line2 mline2">Our counselors will assist
            you in
            shortlisting courses based on your personal and academic profile and will guide you through the
            decision-making process.
          </span>
        </div>
        <div fxFlex="calc(33%-50px)" fxFlex.lt-md="calc(50% - 20px)" fxFlex.lt-sm="calc(100%-10px)" class="borderBox-1"
          fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
          <img src="../../assets/images/Landing-page/Documentation & application submission.png" width="50px">
          <p class="bTxt txtRegular txtCenter no-select font-sv pbh tbc line2 mline2">Documentation & application
            submission
          </p>
          <span class="bTxt txtRegular txtCenter no-select font-xxvs pnc tbc line2 mline2">We rigorously evaluate and
            review
            each student's application, supporting them with compilation and attestation.
          </span>
        </div>
        <div fxFlex="calc(33%-50px)" fxFlex.lt-md="calc(50% - 20px)" fxFlex.lt-sm="calc(100%-10px)" class="borderBox-1"
          fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
          <img src="../../assets/images/Landing-page/Application Processing.png" width="50px">
          <p class="bTxt txtRegular txtCenter no-select font-sv pbh tbc line2 mline2">Application Processing</p>
          <span class="bTxt txtRegular txtCenter no-select font-xxvs pnc tbc line2 mline2">We guide students to submit
            well-presented applications in order to receive a faster response.
          </span>
        </div>
        <div fxFlex="calc(33%-50px)" fxFlex.lt-md="calc(50% - 20px)" fxFlex.lt-sm="calc(100%-10px)" class="borderBox-1"
          fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
          <img src="../../assets/images/Landing-page/Visa Processing_3.png" width="50px">
          <p class="bTxt txtRegular txtCenter no-select font-sv pbh tbc line2 mline2">Visa Processing</p>
          <span class="bTxt txtRegular txtCenter no-select font-xxvs pnc tbc line2 mline2">Our professionals assist you
            in
            preparing for university, visa interviews, and staying updated about scholarships.
          </span>
        </div>
        <div fxFlex="calc(33%-50px)" fxFlex.lt-md="calc(50% - 20px)" fxFlex.lt-sm="calc(100%-10px)" class="borderBox-1"
          fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
          <img src="../../assets/images/Landing-page/Pre-Departure and Post Landing Assistance.png" width="50px">
          <p class="bTxt txtRegular txtCenter no-select font-sv pbh tbc line2 mline2">Pre-Departure and Post Landing
            Assistance
          </p>
          <span class="bTxt txtRegular txtCenter no-select font-xxvs pnc tbc line2 mline2">Before you go, we will walk
            you
            through all of the paperwork and post-visa preparations. We also help with post-landing procedures.
          </span>
        </div>
        <div></div>
      </div>
    </div>
  </div>
  <div class="counts padtb-50 ">
    <div class="padtb-50" fxLayout="row" fxLayout.lt-md="row wrap" fxLayout.lt-sm="column" fxLayoutGap="30px"
      fxLayoutGap.lt-sm="0px">
      <div class="padtb-20" fxLayout="column" fxLayoutAlign="center center" fxFlex="calc(25%-30px)"
        fxFlex.lt-md="calc(50%-30px)" fxFlex.lt-sm="calc(100%-0px)" fxLayoutGap="20px" fxLayoutGap.lt-sm="20px">
        <img class="counts-list" src="../../assets/images/Landing-page/counts/colleges.png" alt="" width="20%">
        <p class="counts-list font-slr wTxt txtBlack pbh13 ">{{collegeCount}}</p>
        <span class=" counts-list wTxt txt20 pbh1  lfont-s">Colleges enroled</span>
      </div>
      <div class="padtb-20" fxLayout="column" fxLayoutAlign="center center" fxFlex="calc(25%-30px)"
        fxFlex.lt-md="calc(50%-30px)" fxFlex.lt-sm="calc(100%-0px)" fxLayoutGap="20px" fxLayoutGap.lt-sm="20px">
        <img class="counts-list" src="../../assets/images/Landing-page/counts/admission.png" alt="" width="20%">
        <p class="counts-list font-slr wTxt txtBlack pbh13">{{admissionCount}}</p>
        <span class="counts-list wTxt txt20 pbh1  lfont-s">Successfull admission</span>
      </div>
      <div class="padtb-20" fxLayout="column" fxLayoutAlign="center center" fxFlex="calc(25%-30px)"
        fxFlex.lt-md="calc(50%-30px)" fxFlex.lt-sm="calc(100%-0px)" fxLayoutGap="20px" fxLayoutGap.lt-sm="20px">
        <img class="counts-list" src="../../assets/images/Landing-page/counts/applicant.png" alt="" width="20%">
        <p class="counts-list font-slr wTxt txtBlack pbh13">{{applicantsCount + "+"}}</p>
        <span class="counts-list wTxt txt20 pbh1  lfont-s">Applicants applied</span>
      </div>
      <div class="padtb-20" fxLayout="column" fxLayoutAlign="center center" fxFlex="calc(25%-30px)"
        fxFlex.lt-md="calc(50%-30px)" fxFlex.lt-sm="calc(100%-0px)" fxLayoutGap="20px" fxLayoutGap.lt-sm="20px">
        <img class="counts-list" src="../../assets/images/Landing-page/counts/applicant.png" alt="" width="20%">
        <p class="counts-list font-slr wTxt txtBlack pbh13">{{collegeCount}}</p>
        <span class="counts-list wTxt txt20 pbh1  lfont-s">Applicants applied</span>
      </div>
    </div>
  </div>
  <div class="Testimonials padtb-50" fxLayout="column">
    <span class="font-s txtBold txtCenter pbh1 tbh">TESTIMONIALS</span>
    <div class="padtb-50" fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="80%" fxFlex.sm="90%" fxFlex.xs="95%">
        <owl-carousel-o [options]="testimonialOptions">
          <ng-template carouselSlide>
            <div fxLayout="column" fxLayoutGap="30px" class="pad-10">
              <div class="card pad-20" fxLayout="column" fxLayoutGap="20px">
                <img src="../../assets/images/Landing-page/quote.png" alt="" width="30px">
                <span class="bTxt txtRegular txtJustify no-select font-xxvs pnc tbc line2 mline2">Lorem ipsum dolor sit
                  amet consectetur adipisicing elit. Dignissimos, quisquam dicta ad quibusdam est quia vitae temporibus
                  odio atque consequatur nihil veniam. Voluptas vitae, similique eligendi ut cum quos libero.</span>
                <div>
                  <mat-icon class="aTxt">star</mat-icon>
                  <mat-icon class="aTxt">star</mat-icon>
                  <mat-icon class="aTxt">star</mat-icon>
                  <mat-icon class="aTxt">star</mat-icon>
                  <mat-icon class="aTxt">star</mat-icon>
                </div>
              </div>
              <div class="clients" fxLayout="row" fxLayoutGap="20px">
                <img src="../../assets/images/Landing-page/cliente.png">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap.lt-sm="10px">
                  <span class="pTxt txtBold txtCenter no-select font-sv pbh tbc line2">Vishwas</span>
                  <span class="bTxt txtRegular txtCenter no-select font-xxvs pnc tbc line2 mline2">Developer</span>
                </div>
              </div>
            </div>

          </ng-template>
          <ng-template carouselSlide>
            <div fxLayout="column" fxLayoutGap="30px" class="pad-10">
              <div class="card pad-20" fxLayout="column" fxLayoutGap="20px">
                <img src="../../assets/images/Landing-page/quote.png" alt="" width="30px">
                <span class="bTxt txtRegular txtJustify no-select font-xxvs pnc tbc line2 mline2">Lorem ipsum dolor sit
                  amet consectetur adipisicing elit. Dignissimos, quisquam dicta ad quibusdam est quia vitae temporibus
                  odio atque consequatur nihil veniam. Voluptas vitae, similique eligendi ut cum quos libero.</span>
                <div>
                  <mat-icon class="aTxt">star</mat-icon>
                  <mat-icon class="aTxt">star</mat-icon>
                  <mat-icon class="aTxt">star</mat-icon>
                  <mat-icon class="aTxt">star</mat-icon>
                  <mat-icon class="aTxt">star</mat-icon>
                </div>
              </div>
              <div class="clients" fxLayout="row" fxLayoutGap="20px">
                <img src="../../assets/images/Landing-page/cliente.png">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap.lt-sm="10px">
                  <span class="pTxt txtBold txtCenter no-select font-sv pbh tbc line2">Vishwas</span>
                  <span class="bTxt txtRegular txtCenter no-select font-xxvs pnc tbc line2 mline2">Developer</span>
                </div>
              </div>
            </div>

          </ng-template>
          <ng-template carouselSlide>
            <div fxLayout="column" fxLayoutGap="30px" class="pad-10">
              <div class="card pad-20" fxLayout="column" fxLayoutGap="20px">
                <img src="../../assets/images/Landing-page/quote.png" alt="" width="30px">
                <span class="bTxt txtRegular txtJustify no-select font-xxvs pnc tbc line2 mline2">Lorem ipsum dolor sit
                  amet consectetur adipisicing elit. Dignissimos, quisquam dicta ad quibusdam est quia vitae temporibus
                  odio atque consequatur nihil veniam. Voluptas vitae, similique eligendi ut cum quos libero.</span>
                <div>
                  <mat-icon class="aTxt">star</mat-icon>
                  <mat-icon class="aTxt">star</mat-icon>
                  <mat-icon class="aTxt">star</mat-icon>
                  <mat-icon class="aTxt">star</mat-icon>
                  <mat-icon class="aTxt">star</mat-icon>
                </div>
              </div>
              <div class="clients" fxLayout="row" fxLayoutGap="20px">
                <img src="../../assets/images/Landing-page/cliente.png">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap.lt-sm="10px">
                  <span class="pTxt txtBold txtCenter no-select font-sv pbh tbc line2">Vishwas</span>
                  <span class="bTxt txtRegular txtCenter no-select font-xxvs pnc tbc line2 mline2">Developer</span>
                </div>
              </div>
            </div>

          </ng-template>
          <ng-template carouselSlide>
            <div fxLayout="column" fxLayoutGap="30px" class="pad-10">
              <div class="card pad-20" fxLayout="column" fxLayoutGap="20px">
                <img src="../../assets/images/Landing-page/quote.png" alt="" width="30px">
                <span class="bTxt txtRegular txtJustify no-select font-xxvs pnc tbc line2 mline2">Lorem ipsum dolor sit
                  amet consectetur adipisicing elit. Dignissimos, quisquam dicta ad quibusdam est quia vitae temporibus
                  odio atque consequatur nihil veniam. Voluptas vitae, similique eligendi ut cum quos libero.</span>
                <div>
                  <mat-icon class="aTxt">star</mat-icon>
                  <mat-icon class="aTxt">star</mat-icon>
                  <mat-icon class="aTxt">star</mat-icon>
                  <mat-icon class="aTxt">star</mat-icon>
                  <mat-icon class="aTxt">star</mat-icon>
                </div>
              </div>
              <div class="clients" fxLayout="row" fxLayoutGap="20px">
                <img src="../../assets/images/Landing-page/cliente.png">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap.lt-sm="10px">
                  <span class="pTxt txtBold txtCenter no-select font-sv pbh tbc line2">Vishwas</span>
                  <span class="bTxt txtRegular txtCenter no-select font-xxvs pnc tbc line2 mline2">Developer</span>
                </div>
              </div>
            </div>

          </ng-template>
        </owl-carousel-o>
      </div>
    </div>

  </div>

  <div class="service-content mpad-10 " fxLayout="column" fxLayoutAlign="center center">
    <div class="service-content-page w90 padtb-50 mw100" fxLayout="row wrap" fxLayoutGap="50px">
      <div class="content-left txtJustify mpad-10 " fxLayout="column" fxLayoutAlign="center none"
        fxFlex="calc(50%-50px)" fxFlex.lt-md="calc(100%-50px)">
        <p class="font-s txtBold pTxt pbh1 pad-10">Watch video to know more</p>
        <span class="font-vs txtRegular line4 txtJustify pbc mline3 tbc">
          EduQuest organized a training Bootcamp in association with ThinkTomi- School of Innovation, Silicon Valley to
          help the budding entrepreneurs in Cambodia. The event was graced by many expert business leaders and
          innovators who shared their valuable learning with the audience.
        </span>
      </div>
      <div class="content-right mpad4-t" fxLayout="row" fxLayoutAlign="center center" fxFlex="calc(50%-50px)"
        fxFlex.lt-md="calc(100%-50px)">
        <iframe width="500" height="300" [src]="displayURL">
        </iframe>
      </div>
    </div>
  </div>
  <div class="padding mpad0">
    <div class="newsletter pad-50 mpad-10" fxLayout="row wrap" fxLayoutGap="30px" fxLayoutGap.lt-sm="10px">
      <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="30px" fxFlex="calc(40%-30px)"
        fxFlex.lt-md="calc(100%-30px)" fxFlex.lt-sm="calc(100%-10px)">
        <span class="font-xvs wTxt pbh tbh lfont-s">NEWSLETTER</span>
        <span class="fs-40 txtBold linee4 wTxt tline lfont-l linevss">Join our community of students</span>
      </div>
      <div class="home-content-form mmar-15" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px"
      fxFlex="calc(60%-30px)" fxFlex.lt-md="calc(100%-20px)" fxFlex.lt-sm="calc(100%-0px)">
      <span class="fs-22 txtBold line3 wTxt mar-1tb lfont-s">GET IN TOUCH WITH US</span>
      <form [formGroup]="landingPageForm" class="w90 mw70">
        <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.lt-md="10px" fxLayoutGap.lt-sm="0px">
          <mat-form-field appearance="outline" class="blackBorder" fxFlex="calc(50%-10px)"
            fxFlex.lt-md="calc(100%-10px)" fxFlex.lt-sm="calc(100%-0px)">
            <mat-label class="wTxt font-vv pbc tbc">Name</mat-label>
            <input class="wTxt" matInput placeholder="Enter your name" formControlName="name" required>
          </mat-form-field>
          <mat-form-field appearance="outline" class="blackBorder" fxFlex="calc(50%-10px)"
            fxFlex.lt-md="calc(100%-10px)" fxFlex.lt-sm="calc(100%-0px)">
            <mat-label class="wTxt font-vv pbc tbc">Email</mat-label>
            <input class="wTxt" matInput placeholder="Enter your email" formControlName="email" required>
          </mat-form-field>
          <div fxLayout="row nowrap" fxFlex="calc(50%-10px)"
                fxFlex.lt-md="calc(100%-10px)" fxFlex.lt-sm="calc(100%-0px)">
                    <div fxFlex="60px" fxLayout="row" class="country-code-form">
                        <mat-form-field fxFlex="100%" appearance="outline" class="blackBorder">
                            <input matInput ng2TelInput [ng2TelInputOptions]="{initialCountry: 'in'}"
                                (countryChange)="onCountryChange($event)" placeholder="-">
                        </mat-form-field>
                    </div>
                    <div fxFlex="" fxLayout="row" class="mobile-form">
                        <mat-form-field appearance="outline" class="blackBorder" fxFlex="100%">
                            <mat-label class="wTxt font-vv pbc tbc" >Mobile</mat-label>
                            <input matInput formControlName="phoneNo" placeholder="Enter mobile number"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                maxlength="10" minlength="10" aria-label="mobile" required>
                        </mat-form-field>
                    </div>
                </div>
          <mat-form-field appearance="outline" class="blackBorder" fxFlex="calc(50%-10px)"
            fxFlex.lt-md="calc(100%-10px)" fxFlex.lt-sm="calc(100%-0px)">
            <mat-label class="wTxt font-vv pbc tbc">City</mat-label>
            <input class="wTxt" matInput placeholder="Enter city " formControlName="address" required>
          </mat-form-field>
          <mat-form-field appearance="outline" class="blackBorder" fxFlex="calc(100%-10px)">
            <mat-label  class="wTxt font-vv pbc tbc">Select an Country</mat-label>
            <mat-select formControlName="country" required>
              <mat-option [value]="item.countryId" *ngFor="let item of countryList">{{
                item.countryName
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="blackBorder" fxFlex="calc(100%-10px)">
            <mat-label class="wTxt font-vv pbc tbc">Highest Level of Education</mat-label>
            <mat-select name="degreetype" formControlName="educationType" required>
              <mat-option [value]="data.id" *ngFor="let data of degreeTypes">{{
                data.name
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="
        selectedEducationType == 1 ||
        selectedEducationType == 2 ||
        selectedEducationType == 3 ||
        selectedEducationType == 4
      " fxLayout="column" fxFlex="calc(100%-20px)" fxFlex.lt-md="calc(100%-20px)" fxFlex.lt-sm="calc(100%-10px)"
            fxLayoutAlign="start none" fxLayoutGap="10px">
            <mat-form-field appearance="outline" class="blackBorder">
              <mat-label class="wTxt font-vv pbc tbc">Completion Year</mat-label>
              <input class="wTxt" matInput placeholder="" formControlName="completionYear" required  oninput="this.value= this.value.replace(/[^0-9.]/g,
              '').replace(/(\..*)\./g, '$1');" autocomplete="off" />
            </mat-form-field>
          </div>
          <div *ngIf="
    selectedEducationType == 1 ||
    selectedEducationType == 2 ||
    selectedEducationType == 3 ||
    selectedEducationType == 4
  " fxLayout="column" fxFlex="calc(100%-20px)" fxFlex.lt-md="calc(100%-20px)" fxFlex.lt-sm="calc(100%-10px)"
            fxLayoutAlign="start none" fxLayoutGap="10px">
            <mat-form-field appearance="outline" class="blackBorder">
              <mat-label class="wTxt font-vv pbc tbc">School or College</mat-label>
              <input class="wTxt" matInput placeholder="" formControlName="schoolOrCollegeName" required />
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutGap="20px">
            <mat-label class="wTxt font-vs pbc tbc">Preferred Study Destination</mat-label>
            <div fxLayout="row wrap" fxLayoutGap="20px">
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" fxLayout="row wrap"
              fxLayoutGap="20px" formControlName="destination" required>
              <mat-radio-button class="wTxt font-vv pbc tbc" [value]="data.name"
                *ngFor="let data of destinationList">{{data.name}}</mat-radio-button>
            </mat-radio-group>
            </div>
          </div>
        </div>
      </form>

      <button mat-raised-button class="gradient w30" (click)="onSubmit()">Submit</button>
    </div>
    </div>
  </div>
</div>

<div style="background-color: #002043;padding: 50px 0 0 0">
  <div class="padding" fxLayout="row wrap" fxLayoutGap="20px" fxLayoutGap.lt-md="20px" fxLayoutGap.lt-sm="0px">
    <div fxFlex="calc(45%-20px)" fxFlex.lt-md="calc(100%-20px)" fxFlex.lt-sm="calc(100%-0px)">
      <div fxLayout="column" fxLayoutGap="20px" class="mb-20">
        <div fxLayout="column" fxLayoutGap="10px">
          <img alt="collegenodu Logo" class="link" routerLink="/home"
            src="../../assets/images/homeScreen/EduQuest_logo.png" width="230px">
        </div>

        <div class="w80" fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="column">
            <div fxLayout="row" fxLayoutGap="5px">
              <mat-icon class="aTxt">location_on</mat-icon>
              <p class="font-ss line6 wTxt pbc tc lcc font-vsv line2 mline3">Head Office: 77 City Center Drive, Suite
                501,East Tower, Mississauga, ON, L5B 1M5</p>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div fxFlex="calc(15%-20px)" fxFlex.lt-md="calc(100%-20px)" fxFlex.lt-sm="calc(100%-0px)">
      <div class="mb-20" fxLayout="column" fxLayoutGap="15px">
        <p class="txt20 aTxt pbh">Quick Links</p>
        <div class="footer-list" fxLayout="column">
          <a class="font-ss line6 wTxt link pbc tc lcc font-vsv line2 mline3" href="#home">Home</a>
          <a class="font-ss line6 wTxt link pbc tc lcc font-vsv line2 mline3" href="#aboutus">About Us</a>
          <a class="font-ss line6 wTxt link pbc tc lcc font-vsv line2 mline3" href="#studyabroad">Study Abroad</a>
          <a class="font-ss line6 wTxt link pbc tc lcc font-vsv line2 mline3" href="#">Contact Us</a>
        </div>
      </div>
    </div>
    <div fxFlex="calc(25%-20px)" fxFlex.lt-md="calc(100%-20px)" fxFlex.lt-sm="calc(100%-0px)">
      <div class="mb-20" fxLayout="column" fxLayoutGap="15px">
        <p class="txt20 aTxt pbh">Reach Us</p>
        <div fxLayout="column" fxLayoutGap="15px">
          <div fxLayout="row" fxLayoutAlign="none center" fxLayoutGap="5px">
            <mat-icon class="aTxt">mail_outline</mat-icon>
            <a href="mailto:info@eduquesthub.com" class="font-ss line6 wTxt pbc tc lcc font-vsv line2">
              info@eduquesthub.com</a>
          </div>
          <div fxLayout="row" fxLayoutGap="5px">
            <mat-icon class="aTxt">support_agent</mat-icon>
            <a href="tel:+1 (647) 669-7374" class="font-ss line6 wTxt pbc tc lcc font-vsv line2 mline3">+1 (647)
              669-7374</a>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" class="socialIcons">
            <button mat-icon-button matTooltip="Facebook" matTooltipPosition="below">
              <a href="https://www.facebook.com/eduquesthub" target="_blank">
                <img alt="Facebook logo" src="../../assets/images/icons/fb.png">
              </a>
            </button>
            <button mat-icon-button matTooltip="Twitter" matTooltipPosition="below">
              <a href="https://twitter.com/eduquesthub" target="_blank">
                <img alt="Twitter logo" src="../../assets/images/icons/twitter.png">
              </a>
            </button>
            <button mat-icon-button matTooltip="Instagram" matTooltipPosition="below">
              <a href="https://www.instagram.com/eduquesthub/" target="_blank">
                <img alt="Instagram logo" src="../../assets/images/icons/insta.png">
              </a>
            </button>
            <button mat-icon-button matTooltip="LinkedIn" matTooltipPosition="below">
              <a href="https://www.linkedin.com/company/eduquesthub/" target="_blank">
                <img alt="LinkedIn logo" src="../../assets/images/icons/linkedin.png">
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div style="background-color: #071D33;" class="padding">
  <div style="padding: 5px 0;" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutAlign.lt-sm="center">
    <p fxLayout="row" fxLayoutAlign="center center" fxFlex="50%" fxFlex.lt-sm="100%"
      class="wTxt font-xvss copyrightTxt LessMargin pbc txtCenter">@ 2023 Copyright & All Rights are Reserved by
      EduQuest Hub Company Ltd.</p>
  </div>
</div>