import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pop-up-success',
  templateUrl: './pop-up-success.component.html',
  styleUrls: ['./pop-up-success.component.scss']
})
export class PopUpSuccessComponent implements OnInit {
  Result: string;

  constructor(
    public dialogRef: MatDialogRef<PopUpSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.Result = this.data;
  }
}
