<div class="dialog">
    <div fxLayoutAlign="space-between start">
        <h1 class="txt" matDialogTitle>Upload Image</h1>
        <button mat-icon-button color="warn" (click)="onNoClick()" [mat-dialog-close]='false'>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="100%">
            <button (click)="fileCtrl.click()" mat-raised-button color="accent" class="wTxt">
                <mat-icon>file_upload</mat-icon>
                &nbsp; Upload File
            </button>
        </div>

        <input type="file" accept="image/*" hidden (change)="fileChangeEvent ($event)" #fileCtrl />

        <div fxFlex="calc(50% -10px)">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
                (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()" width="100%"></image-cropper>
        </div>
        <div fxFlex="calc(50% -10px)">
            <img [src]="croppedImage" width="100%" />
        </div>
    </div>
    <mat-dialog-actions fxLayoutAlign="end end">
        <button mat-button matDialogClose color="primary">Cancel</button>
        <button mat-raised-button (click)="onSubmit()" color="accent" class="wTxt">Submit</button>
    </mat-dialog-actions>
</div>
