export const constant = {
  roles: {
    Role_College: 'Role_College',
    Role_User: 'Role_User',
    Role_Admin: 'Role_Admin'
  },
  authData: {
    access_token: 'access_token',
    refresh_token: 'refresh_token'
  },
  mainAccount: {
    photo_path: 'photo_path',
    account_name: 'username',
    account_gender: 'account_gender'
  }
};
