import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  CollegeForm: FormGroup;
  getExamForm: FormGroup;
  contactGet = false;
  universityGet = false;
  examGet = false;
  collegeGet = false;


  constructor(
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.CollegeForm = this.fb.group({
      pageIndex: [0],
      pageSize: [5],
      cityId: [0],
      stateId: [0],
      countryId: [1],
      courseId: [],
      universityId: [],
      sortByAlphabets: [false],
      searchString: [null],
    });
    this.getExamForm = this.fb.group({
      pageIndex: [0],
      pageSize: [5],
      levelId: [],
      universityId: []
    });
  }

  Search(id: any) {
    this.router.navigate(['/user/search-college'], {
      queryParams: {
        universityId: id
      }
    });
  }

  getTopColleges(): void {
    const courseModel = {
      searchString: null,
      multipleCouseFilterModel: [],
      multipleUniversity: [],
      sortByAlphabets: false
    };

  }


trigger = 0;
mouseEnter(trigger: MatMenuTrigger) {
  setTimeout(() => {    
    if (!trigger.menuOpen) {
      trigger.openMenu();
    }
    this.trigger = 0;
  }, 1);
}
mouseLeave(trigger: MatMenuTrigger) {
  setTimeout(() => {
    if (trigger.menuOpen && this.trigger === 0) {
      trigger.closeMenu();
    }
  }, 200);
}
menuMouseLeave(trigger: MatMenuTrigger) {
  setTimeout(() => {
    if (trigger.menuOpen) {
      trigger.closeMenu();
    }
    this.trigger = 0;
  }, 1);
}
}