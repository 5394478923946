import { Component, OnDestroy, OnInit } from '@angular/core';
// import { CollegeProfileService, StudentProfileModel, StudentProfileService } from 'build/openapi';
import { Subscription } from 'rxjs';
import { AccountService } from '../services/account.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {

  isCollegeLogin: boolean;
  isStudentLogin: boolean;
  isAdminLogin: boolean;
  loginSubscription: Subscription;
  authentication = false;
  // showStudentData: StudentProfileModel[];
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  miniMode = false;
  profileUrl: string;
  isAuthenticated = false;
  imagePath = '';
  isEcomPortal: boolean;
  profilePhoto: string;
  profileName: string;
  clgName: string;
  clgPhoto: string;

  constructor(
    private accountService: AccountService,
    // private collegeProfile: CollegeProfileService,
    // private studentService: StudentProfileService
  ) { }

  ngOnDestroy(): void {
    // Destroy Subscriptions
    this.loginSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.loginSubscription = this.accountService.loginListner$.subscribe(data => {
      this.authentication = data;
      if (this.accountService.isAdminLogin) {
        this.isAdminLogin = this.accountService.isAdminLogin;
      }
      else if (this.accountService.isCollegeLogin) {
        this.isCollegeLogin = this.accountService.isCollegeLogin;
      }
      else {
        this.accountService.loginListner$.subscribe(profileData => {
          data = profileData;
        });
        this.isStudentLogin = this.accountService.isStudentLogin;
      }
    });
    // if (this.accountService.isStudentLogin) {
    //   this.getStudentDetails();
    // }
    // if (this.accountService.isCollegeLogin) {
    //   this.getClgData();
    // }
  }
  // getStudentDetails() {
  //   this.studentService.studentStudentProfileGetStudentDetailGet().subscribe(data => {
  //     if (data.data.length === 0) {
  //       this.profileName = 'Student';
  //     } else {
  //       this.profilePhoto = data.data[0].profilePhoto;
  //       this.profileName = data.data[0].name;
  //     }
  //   });
  // }

  // getClgData() {
  //   this.collegeProfile.collegeCollegeProfileGetMyCollegeInformationGet().subscribe(res => {
  //     if (res.data.length === 0) {
  //       this.clgName = 'Student';
  //     }
  //     else {
  //       this.clgPhoto = res.data[0].logoPath;
  //       this.clgName = res.data[0].collegeName;
  //     }
  //   });
  // }

  get menuItems(): MenuItem[] {
    if (this.isAdminLogin) {
      return AdminMenus;
    }
    else if (this.isStudentLogin) {
      return userMenus;
    }
    else if (this.isCollegeLogin) {
      return collegeMenus;
    }
  }

}

export interface MenuItem {
  icon?: string;
  title?: string;
  link?: string;
  items?: MenuItem[];
  isExpandable?: boolean;
}

export const userMenus: MenuItem[] = [
  { icon: 'person', title: 'Student Profile', link: 'student/edit-profile', isExpandable: false, items: [] },
  { icon: 'bookmark_added', title: 'Favorite Colleges', link: 'student/favourites', isExpandable: false, items: [] },
  { icon: 'where_to_vote', title: ' Applied Colleges', link: 'student/applied', isExpandable: false, items: [] },
  { icon: 'auto_stories', title: ' Applied Exams', link: 'student/exams', isExpandable: false, items: [] }
];

export const AdminMenus: MenuItem[] = [
  { icon: 'home', title: 'Overview', link: 'admin/overview', isExpandable: false, items: [] },
  { icon: 'person', title: 'College Applied', link: 'admin/users', isExpandable: false, items: [] },
  { icon: 'auto_stories', title: 'Exam Applied', link: 'admin/exam-applied', isExpandable: false, items: [] },
  { icon: 'local_library', title: 'Manage Colleges', link: 'admin/colleges', isExpandable: false, items: [] },
  { icon: 'school', title: 'Add Colleges', link: 'admin/add-college', isExpandable: false, items: [] },
  { icon: 'tty', title: 'Contact us', link: 'admin/contact', isExpandable: false, items: [] },
  { icon: 'message', title: 'Chat', link: 'admin/chat', isExpandable: false, items: [] },
  {
    icon: 'read_more', title: 'More', link: '', isExpandable: true, items: [
      { icon: 'school', title: 'Manage Categories', link: 'admin/categories', isExpandable: false, items: [] },
      { icon: 'rss_feed', title: 'Manage Blog', link: 'admin/blog', isExpandable: false, items: [] },
      { icon: 'cast_for_education', title: 'Manage Exam', link: 'admin/addExams', isExpandable: false, items: [] },
      { icon: 'location_city', title: 'Manage University', link: 'admin/university', isExpandable: false, items: [] },
      { icon: 'travel_explore', title: 'Manage Countries', link: 'admin/add-countries', isExpandable: false, items: [] },
      { icon: 'contact_support', title: 'Manage Counselling', link: 'admin/councelling', isExpandable: false, items: [] },
      { icon: 'table_view', title: 'common-application', link: 'admin/common-application-form', isExpandable: false, items: [] },
      { icon: 'feed', title: 'Trending notification', link: 'admin/manage-notification', isExpandable: false, items: [] },
    ]
  },
];

export const collegeMenus: MenuItem[] = [
  { icon: 'domain', title: 'College Profile', link: 'college/edit-profile', isExpandable: false, items: [] },
  { icon: 'pending', title: 'Overview', link: 'college/edit-overview', isExpandable: false, items: [] },
  { icon: 'pending_actions', title: 'Courses & Fees', link: 'college/edit-courses', isExpandable: false, items: [] },
  { icon: 'assignment_turned_in', title: 'Admission Process', link: 'college/edit-admission', isExpandable: false, items: [] },
  { icon: 'filter', title: 'Gallery', link: 'college/edit-gallery', isExpandable: false, items: [] },
  { icon: 'supervisor_account', title: 'Faculty', link: 'college/edit-faculty', isExpandable: false, items: [] },
  { icon: 'cast_for_education', title: 'Exams', link: 'college/edit-exam', isExpandable: false, items: [] },
  { icon: 'quiz', title: 'Q & A', link: 'college/edit-faq', isExpandable: false, items: [] },
  { icon: 'picture_as_pdf', title: 'Add Brochure', link: 'college/add-brochure', isExpandable: false, items: [] },
];

