<div fxLayout="column" style="min-width:260px;max-width: 260px;min-height: 115vh;">
  <ng-scrollbar #scrollable style="min-height: 115vh;">
    <div *ngIf="authentication && isStudentLogin">
      <div fxFlex.lt-sm="100%" fxLayout="column" fxLayoutAlign="center center" class="pad-20">
        <img alt="CollegeNodu logo" [src]="profilePhoto" onerror="src='assets/images/person-icon.png'" width="180px">
        <h2 class="wTxt txt" style="margin: 10px; ">{{profileName!==null?(profileName | titlecase):'Student'}}</h2>
      </div>
    </div>
    <div *ngIf="authentication && isCollegeLogin">
      <div fxFlex.lt-sm="100%" fxLayout="column" fxLayoutAlign="center center" class="pad-20">
        <img alt="CollegeNodu logo" [src]="clgPhoto" onerror="src='assets/images/collegeNoduLogo.png'" width="180px">
        <h2 class="wTxt txt" style="margin: 10px; " matTooltip="{{clgName}}">
          {{ (clgName?.length>15)? (clgName | slice:0:15)+'..':(clgName!==null?clgName:'College') }}</h2>
      </div>
    </div>
    <mat-accordion class="CustomSideNav" [ngClass]="[miniMode ? 'miniCustomNavBar' : 'normCustomNavBar']">
      <ng-container *ngFor="let item of menuItems">
        <ng-container [ngSwitch]="item.isExpandable">
          <mat-expansion-panel *ngSwitchCase="true" class="mat-elevation-z0"
            [ngClass]="[miniMode ? 'miniHeadList' : 'normHeadList']">
            <mat-expansion-panel-header [collapsedHeight]="'28px'" [expandedHeight]="'28px'">
              <span class="linkHead material-icons">{{item.icon}}</span>
              <span *ngIf="!miniMode" class="linkBody" style="font-weight: bold;"> {{item.title}} </span>
            </mat-expansion-panel-header>
            <mat-accordion class="CustomSideNav">
              <ng-container *ngFor="let item1 of item.items">
                <ng-container [ngSwitch]="item1.isExpandable">
                  <mat-expansion-panel *ngSwitchCase="true" class="mat-elevation-z0"
                    [ngClass]="[miniMode ? 'miniHeadList' : 'normHeadList']">
                    <mat-expansion-panel-header [collapsedHeight]="'28px'" [expandedHeight]="'28px'">
                      <span class="linkHead">{{item1.icon}}</span>
                      <span *ngIf="!miniMode" class="linkBody"> {{item1.title}} </span>
                    </mat-expansion-panel-header>
                    <mat-accordion class="CustomSideNav">
                      <ng-container *ngFor="let item2 of item1.items">
                        <ng-container [ngSwitch]="item2.isExpandable">
                          <mat-expansion-panel *ngSwitchCase="true" class="mat-elevation-z0"
                            [ngClass]="[miniMode ? 'miniHeadList' : 'normHeadList']">
                            <mat-expansion-panel-header [collapsedHeight]="'28px'" [expandedHeight]="'28px'">
                              <span class="linkHead">{{item2.icon}}</span>
                              <span *ngIf="!miniMode" class="linkBody"> {{item2.title}}
                              </span>
                            </mat-expansion-panel-header>
                            <mat-accordion class="CustomSideNav">
                              <ng-container *ngFor="let item3 of item2.items">
                                <ng-container [ngSwitch]="item3.isExpandable">
                                  <mat-expansion-panel *ngSwitchCase="true" class="mat-elevation-z0"
                                    [ngClass]="[miniMode ? 'miniHeadList' : 'normHeadList']">
                                    <mat-expansion-panel-header [collapsedHeight]="'28px'" [expandedHeight]="'28px'">
                                      <span class="linkHead">{{item3.icon}}</span>
                                      <span *ngIf="!miniMode" class="linkBody">
                                        {{item3.title}} </span>
                                    </mat-expansion-panel-header>
                                    <mat-accordion class="CustomSideNav">
                                      <ng-container *ngFor="let item4 of item3.items">
                                      </ng-container>
                                    </mat-accordion>
                                  </mat-expansion-panel>
                                  <mat-nav-list *ngSwitchDefault class="headList"
                                    [ngClass]="[miniMode ? 'miniHeadList' : 'normHeadList']">
                                    <a mat-list-item routerLinkActive="activee mat-elevation-z5"
                                      [routerLink]="item3.link"><span class="linkHead">{{item3.icon}}</span>
                                      <span *ngIf="!miniMode" class="linkBody">{{item3.title}}</span>
                                    </a>
                                  </mat-nav-list>
                                </ng-container>
                              </ng-container>
                            </mat-accordion>
                          </mat-expansion-panel>
                          <mat-nav-list *ngSwitchDefault class="headList"
                            [ngClass]="[miniMode ? 'miniHeadList' : 'normHeadList']">
                            <a mat-list-item routerLinkActive="activee mat-elevation-z5" [routerLink]="item2.link"><span
                                class="linkHead">{{item2.icon}}</span>
                              <span *ngIf="!miniMode" class="linkBody">{{item2.title}}</span>
                            </a>
                          </mat-nav-list>
                        </ng-container>
                      </ng-container>
                    </mat-accordion>
                  </mat-expansion-panel>
                  <mat-nav-list *ngSwitchDefault class="headList"
                    [ngClass]="[miniMode ? 'miniHeadList' : 'normHeadList']">
                    <a mat-list-item routerLinkActive="activee mat-elevation-z5" [routerLink]="item1.link"><span
                        class="linkHead material-icons">{{item1.icon}}</span>
                      <span *ngIf="!miniMode" class="linkBody">{{item1.title}}</span>
                    </a>
                  </mat-nav-list>
                </ng-container>
              </ng-container>
            </mat-accordion>
          </mat-expansion-panel>
          <mat-nav-list *ngSwitchDefault class="headList" [ngClass]="[miniMode ? 'miniHeadList' : 'normHeadList']">
            <a mat-list-item routerLinkActive="activee mat-elevation-z0" [routerLink]="item.link"><span
                class="linkHead material-icons">{{item.icon}}</span>
              <span *ngIf="!miniMode" class="linkBody">{{item.title}}</span>
            </a>
          </mat-nav-list>
        </ng-container>
      </ng-container>
    </mat-accordion>
  </ng-scrollbar>
</div>