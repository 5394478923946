import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { constant } from '../constant';
import { Router } from '@angular/router';
// import { AccountsService, CollegeProfileService, Configuration } from 'build/openapi';
import { ModuleRouteModel } from '../app.component';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    public userImage = '';
    public userName = '';
    public userEmail = '';
    public userGender = '';
    private userDetails: UserDetails;
    private collegeLogin = false;
    private studentLogin = false;
    private adminLogin = false;
    private _loginListener = new BehaviorSubject<boolean>(false);
    loginListner$ = this._loginListener.asObservable();
    private _userDetails = new BehaviorSubject<UserDetails>(undefined);
    userDetailsListner$ = this._userDetails.asObservable();
    get isCollegeLogin() {
        return this.collegeLogin;
    }
    get isStudentLogin() {
        return this.studentLogin;
    }
    get isAdminLogin() {
        return this.adminLogin;
    }
    // private _moduleListener = new BehaviorSubject<ModuleRouteModel>({ studentRoute: false, collegeRoute: false });
    // moduleListner$ = this._moduleListener.asObservable();
    // emitModuleChange(modulee: ModuleRouteModel) {
    //   this._moduleListener.next(modulee);
    // }
    constructor(
        // private tokenService: AccountsService,
        private router: Router,
        // private configuration: Configuration,
        // private collegeProfile: CollegeProfileService,
    ) { }

    // public refreshToken(): Observable<string> {
    //     return this.tokenService.ledgerAccountsRefreshTokenPut(this.storage.getItem(constant.authData.refresh_token)).pipe(
    //         map(res => {
    //             this.configuration.credentials['bearer'] = `bearer ${res.data.accessToken}`;
    //             let accessToken = res.data.accessToken;
    //             let refreshToken = res.data.refreshToken;
    //             this.storage.setItem(constant.authData.access_token, accessToken);
    //             this.storage.setItem(constant.authData.refresh_token, refreshToken);
    //             this.collegeLogin = this.checkUserRole(constant.roles.Role_College);
    //             this.studentLogin = this.checkUserRole(constant.roles.Role_User);
    //             this.adminLogin = this.checkUserRole(constant.roles.Role_Admin);
    //             this.checkLogin();
    //             return accessToken;
    //         })
    //     );
    // }

    checkLogin() {
        if (this.storage.getItem(constant.authData.access_token)) {
            try {
                const data = this.parseJwt(this.storage.getItem(constant.authData.access_token));
                this.collegeLogin = data.role === constant.roles.Role_College;
                this.studentLogin = data.role === constant.roles.Role_User;
                this.adminLogin = data.role === constant.roles.Role_Admin;
                this.userName = this.storage.getItem(constant.mainAccount.account_name);
                this.userGender = this.storage.getItem(constant.mainAccount.account_gender);
                this.userImage = this.storage.getItem(constant.mainAccount.photo_path);
                // this.configuration.credentials['bearer'] = `bearer ${this.storage.getItem(constant.authData.access_token)}`;
                this._loginListener.next(true);
                if (this.userDetails) {
                    this._userDetails.next(this.userDetails);
                }
                if (this.adminLogin) {
                }
                if (this.studentLogin) {
                }
                if (this.collegeLogin) {
                }
            } catch (error) {
                this.userDetails = undefined;
                this._loginListener.next(false);
                this._userDetails.next(this.userDetails);
            }
        }
        else {
            this.userDetails = undefined;
            this._loginListener.next(false);
            this._userDetails.next(this.userDetails);
        }
    }



    private parseJwt(token: string): any {
        const base64Url = token.split('.')[1];
        const base64 = decodeURIComponent(atob(base64Url).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        var res = JSON.parse(base64) as UserToken;
        return res;
    }

    private checkUserRole(role: string): boolean {
        let token = this.storage.getItem(constant.authData.access_token);
        if (!token) {
            return false;
        }
        let roles = this.parseJwt(token).roles;
        return roles && roles.includes(role);
    }


    get storage(): Storage {
        if (localStorage.getItem('rememberMe')) {
            return localStorage;
        } else {
            return sessionStorage;
        }
    }
    public logout(): void {
        localStorage.clear();
        sessionStorage.clear();
        this.storage.clear();
        this.collegeLogin = false;
        this.studentLogin = false;
        this.adminLogin = false;
        this._loginListener.next(false);
        this.router.navigate(['/account']);
    }

     /**
   * Returns back Date in ISO string format.
   * @param value A string which takes date from reactive form.
   * @returns date in ISO
   */
  dateInIso(value: string): string | null {
    if (value) {
      const date = new Date(value)
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes() - date.getTimezoneOffset()).toISOString();
    } else {
      return null
    }
  }

}
export interface UserDetails {
    userImageUrl: string;
    userEmail: string;
    userFullName: string;
    userGender: string;
}
export class UserToken {
    roles: string;
}
