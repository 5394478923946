import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
// import { AuthModule } from './auth/auth.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LandingPagesComponent } from './landing-pages/landing-pages.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { CollegeComponent } from './college/college.component';
import { SideNavComponent } from './side-nav/side-nav.component';
// import { StudentComponent } from './student/student.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiModule, Configuration, ConfigurationParameters } from 'build/openapi';
import { environment } from 'src/environments/environment';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
// import { AdminComponent } from './admin/admin.component';
import { DndDirective } from './dnd.directive';
import { MainLandingPageComponent } from './main-landing-page/main-landing-page.component';
import { CanadaUniversityListComponent } from './canada-university-list/canada-university-list.component';




export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.basePath,
    apiKeys: { 'bearer': '' }
  };
  return new Configuration(params);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LandingPagesComponent,
    SideNavComponent,
    DndDirective,
    MainLandingPageComponent,
    CanadaUniversityListComponent


  
   
  ],
  imports: [
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    ApiModule.forRoot(apiConfigFactory),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
